@font-face {
    font-family: 'Handjet';
    src: local('Handjet'), url(./fonts/Handjet/Handjet-VariableFont_ELGR,ELSH,wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'ChakraPetch';
    src: local('ChakraPetch'), url(./fonts/Chakra_Petch/ChakraPetch-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }


  